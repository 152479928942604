<template>
  <div class="pb-5">
    <v-row
      :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'space-between'"
      justify-xl="start"
    >
      <v-col
        v-for="product in products.filter(
          (element) => element.category === currentCategoryId
        )"
        :key="product.id"
        cols="12"
        sm="10"
        md="6"
        lg="4"
        xl="3"
      >
        <v-row class="rounded-lg startup-list-card" justify="center">
          <v-col class="pa-2">
            <base-img
              v-if="product.image && product.qty_existence === 0"
              :aspect-ratio="1"
              class="rounded-lg transparent-img"
              :src="`${product.image}`"
              @click="openImageModal(product.image)"
              cover
              max-width="6rem"
            />
            <base-img
              v-else-if="product.image"
              :aspect-ratio="1"
              class="rounded-lg"
              :src="`${product.image}`"
              @click="openImageModal(product.image)"
              cover
              max-width="6rem"
            />
            <base-img
              v-else
              :aspect-ratio="1"
              :src="require('@/assets/no-image.png')"
              class="rounded-lg"
              max-width="6rem"
            />
          </v-col>
          <v-col cols="8" class="pl-5 py-0">
            <v-row align-content="space-between">
              <v-col cols="12">
                <h6 class="text-h6 mb-0" style="word-break: break-word">
                  {{ product.name }}
                </h6>
                <p
                  v-if="product.qty_existence === 0"
                  class="red--text mt-0 mb-0"
                >
                  {{ $t("outOfStock") }}
                </p>
                <p class="text-body-2 grey--text mb-0">
                  {{ product.description ? product.description : "" }}
                </p>
              </v-col>
              <v-col cols="12">
                <v-col
                  v-if="product.allergens && product.allergens[0]"
                  cols="12"
                  class="pb-2 pt-2"
                >
                  <v-row>
                    <v-img
                      class="mr-1"
                      v-for="allergen in product.allergens"
                      :key="allergen"
                      aspect-ratio="1"
                      max-width="1.3rem"
                      :src="require(`@/assets/img/allergens/${allergen}.png`)"
                    ></v-img>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-row justify="space-between" align="center">
                    <p class="text-justify text-h6 font-weight-normal mb-0">
                      {{ formatPrice(+product.price) }}
                    </p>
                    <v-col cols="6" class="px-0">
                      <v-btn
                        :disabled="product.qty_existence === 0"
                        block
                        small
                        color="primary"
                        class="white--text"
                        elevation="0"
                        @click="
                          currentProductId = product.id;
                          dialog = !dialog;
                        "
                      >
                        {{ $t("add") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mt-4" />
      </v-col>
    </v-row>

    <!-- Modal para mostrar la imagen en grande -->
    <v-dialog v-model="isImageModalOpen" max-width="90%">
      <v-card
        class="d-flex justify-center align-center"
        @click="closeImageModal"
      >
        <v-img :src="selectedImage" class="modal-image"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      v-if="dialog && currentProduct"
      v-model="dialog"
      max-width="500"
      @click:outside="
        dialog = !dialog;
        clearCurrentProduct();
      "
    >
      <v-card>
        <v-card-title
          class="blue-grey darken-4 blue-grey--text text--lighten-4 justify-space-between"
        >
          <h3 class="text-h5">{{ $t(getTitleForDialog) }}</h3>
          <v-btn
            icon
            @click="handleCloseDialog"
            @click:outside="handleCloseDialog"
            color="primary"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <template v-if="showSettingOrder && !isKiosk">
          <SettingOrder
            class="my-4 pa-3 white"
            @updateProductPrice="($event) => alert('emit')"
          />
        </template>
        <template v-else>
          <v-card-text class="text-body-1">
            <base-img
              v-if="currentProduct"
              :src="`${
                currentProduct.image || require('@/assets/no-image.png')
              }`"
              :aspect-ratio="currentProduct.image ? 4 / 3 : 1"
              class="rounded-lg my-3 mx-auto"
              :max-width="currentProduct.image ? '320px' : '9rem'"
            />
            <h2 class="headline">{{ currentProduct.name }}</h2>

            <h4 class="my-3">{{ currentProduct.description }}</h4>

            <span v-if="!isKiosk">
              <p class="mt-3">{{ $t("addDetailsProduct") }}</p>
              <v-form v-model="isValid" ref="form">
                <v-textarea
                  v-model="details"
                  :height="100"
                  :rules="rulesLetters"
                  rounded
                  class="pt-2"
                  flat
                  solo
                  outlined
                />
              </v-form>
            </span>
            <v-col
              v-if="currentProduct.allergens && currentProduct.allergens[0]"
              cols="12"
              class="d-flex pb-2 pt-6"
            >
              <v-img
                class="mr-1"
                v-for="allergen in currentProduct.allergens"
                :key="allergen"
                aspect-ratio="1"
                max-width="1.4rem"
                :src="require(`@/assets/img/allergens/${allergen}.png`)"
              />
            </v-col>
            <v-col>
              <ListAggregatesCombined />
            </v-col>
          </v-card-text>
          <p
            v-if="
              currentProduct.qty_existence < 10 &&
              currentProduct.qty_existence > 0 &&
              currentProduct.qty_existence !== null
            "
            class="red--text mt-0 ml-4"
          >
            {{ $t("stockQty") }}: {{ currentProduct.qty_existence }}
          </p>

          <v-card-actions>
            <v-col cols="4" class="accent rounded-xl px-3">
              <v-row justify="space-between" align="center">
                <v-btn
                  :disabled="
                    addingProductQuantity == null ||
                    Number(addingProductQuantity) < 2
                  "
                  color="transparent"
                  x-small
                  plain
                  fab
                  elevation="0"
                  @click="addingProductQuantity--"
                >
                  <v-icon color="secondary">mdi-minus</v-icon>
                </v-btn>
                <span class="mb-0">{{ addingProductQuantity }}</span>
                <v-btn
                  @click="addingProductQuantity++"
                  color="transparent"
                  x-small
                  fab
                  elevation="0"
                  :disabled="
                    currentProduct.qty_existence !== null &&
                    currentProduct.qty_existence === addingProductQuantity
                  "
                >
                  <v-icon color="secondary">mdi-plus</v-icon>
                </v-btn>
              </v-row>
            </v-col>
            <v-spacer />
            <v-btn
              elevation="0"
              small
              class="pa-4 white--text"
              color="primary"
              @click="addToCart()"
              :disabled="!isValid"
            >
              {{ $t("add") }}
              {{
                formatPrice(
                  +(currentProduct && currentProduct.price
                    ? getTotalProductPrice
                    : 0)
                )
              }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import { orderTypeStoreGetters } from "@/store/modules/orderType/constNames";
import { aggregatesStoreGetters } from "@/store/modules/aggregates/constNames";
import { combinedStoreGetters } from "../store/modules/combined/constNames";
import {
  menuStoreGetters,
  menuStoreActions,
} from "../store/modules/menuProducts/constNames";
import { rulesLetters, getIsKioskSetting } from "@/helpers";

export default {
  components: {
    SettingOrder: () => import("@/components/shared/SettingOrder/SettingOrder"),
    ListAggregatesCombined: () =>
      import(
        "@/components/aggregatesAndCombined/ListAggregatesAndCombined.vue"
      ),
  },
  data() {
    return {
      addingProductQuantity: 1,
      currentProductId: null,
      dialog: false,
      details: "",
      isValid: true,
      rulesLetters,
      isImageModalOpen: false,
      selectedImage: "",
    };
  },
  props: {
    currentCategoryId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    products() {
      return this.$store.getters[menuStoreGetters.GET_MENU_PRODUCTS];
    },
    isKiosk() {
      return getIsKioskSetting;
    },
    getTitleForDialog() {
      return this.showSettingOrder ? "selectOrderType" : "add";
    },
    showSettingOrder() {
      return !this.$store.getters[
        orderTypeStoreGetters.GET_ORDER_TYPE_IS_CONFIGURED
      ];
    },
    currentProduct() {
      if (!this.currentProductId) return null;
      return this.products.find(
        (element) => Number(element.id) === Number(this.currentProductId)
      );
    },
    getTotalProductPrice() {
      return (
        this.currentProduct.price * this.addingProductQuantity +
        this.$store.getters[aggregatesStoreGetters.GET_TOTAL_PRICE_AGGREGATES] *
          this.addingProductQuantity +
        this.$store.getters[combinedStoreGetters.GET_TOTAL_PRICE_COMBINED] *
          this.addingProductQuantity
      );
    },
    apiUrl() {
      return process.env.VUE_APP_API;
    },
    disabled() {
      return this.isValid;
    },
  },
  methods: {
    addToCart() {
      if (!this.isValid) return;
      const aggregates =
        this.$store.getters[aggregatesStoreGetters.GET_AGGREGATES];
      const combined = this.$store.getters[combinedStoreGetters.GET_COMBINED];

      const product = {
        ...this.currentProduct,
        details: this.details.trim(),
        items: [...aggregates, ...combined],
        total_price: this.getTotalProductPrice,
      };

      this.$store.dispatch("cart/newProductQty", {
        product,
        addingProductQuantity: this.addingProductQuantity,
      });
      const payload = {
        msg:
          this.addingProductQuantity > 1
            ? i18n.t("productsAdded")
            : i18n.t("productAdded"),
        success: true,
      };
      this.$store.commit("user/OPEN_SNACKBAR", payload);
      this.dialog = false;
      this.clearCurrentProduct();
    },
    clearCurrentProduct() {
      this.addingProductQuantity = 1;
      this.currentProductId = null;
    },
    formatPrice(value) {
      return value.toLocaleString(this.$i18n.locale.toUpperCase(), {
        currency: this.$store.getters["user/getCurrency"],
        style: "currency",
        currencyDisplay: "symbol",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    handleCloseDialog() {
      this.dialog = !this.dialog;
      this.details = "";
      this.clearCurrentProduct();
    },
    updateProductPrice() {
      console.log("updatingCurrentProduct");
      const orderTypeIsLocal =
        this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE_IS_LOCAL];
      this.$store.commit(
        menuStoreActions.UPDATE_MENU_PRODUCTS_PRICES,
        orderTypeIsLocal
      );
    },
    openImageModal(image) {
      this.selectedImage = image;
      this.isImageModalOpen = true;
    },
    closeImageModal() {
      this.isImageModalOpen = false;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    dialog(newValue, _) {
      !newValue && (this.details = "");
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  display: block;
}

.transparent-img {
  opacity: 0.3;
}

.modal-image {
  max-width: 100%;
  object-fit: contain; /* Ajustar la imagen para que se contenga dentro del contenedor */
}
</style>
